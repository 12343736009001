import { $t } from '@/i18n/index'

export const progressStatusType = {
  SOURCE_FETCHING: 'SOURCE_FETCHING',
  RECALCULATE_ADJUSTMENT: 'RECALCULATE_ADJUSTMENT',
  SOURCE_DELETING: 'SOURCE_DELETING',
  TRANCATION_AUTOMATING: 'TRANCATION_AUTOMATING',
  TRADE_AUTOMATING: 'TRADE_AUTOMATING',
  GAIN_LOSS_AUTOMATING: 'GAIN_LOSS_AUTOMATING',
  BUSINESS_DATA_AUTOMATING: 'BUSINESS_DATA_AUTOMATING',
  COUNTERPARTY_TRANSFORMER_AUTOMATING: 'COUNTERPARTY_TRANSFORMER_AUTOMATING',
  DERIVATIVE_FACT_TRANSFORMER_AUTOMATING: 'DERIVATIVE_FACT_TRANSFORMER_AUTOMATING',
  DERIVATIVE_ADJUSTMENT_AUTOMATING: 'DERIVATIVE_ADJUSTMENT_AUTOMATING',
  JOURNAL_GROUP: 'JOURNAL_GROUP',
  POSTING: 'POSTING',
  RECALCULATE_COST_BASIS: 'RECALCULATE_COST_BASIS',
  REPORTING: 'REPORTING',
  RECONCILIATION_AUTOMATING: 'RECONCILIATION_AUTOMATING',
  REFRESH_BALANCE: 'REFRESH_BALANCE',
  ENTITY_ACCOUNT_DELETING: 'ENTITY_ACCOUNT_DELETING'
}

export const exportProgressStatusType = {
  totalPosition: {
    label: $t('project.nav.totalPosition'),
    value: 'TOTAL_POSITION'
  },
  defiPosition: {
    label: $t('project.nav.defiPosition'),
    value: 'DEFI_POSITIONS'
  },
  treasury: {
    label: $t('project.nav.treasury'),
    value: 'TREASURY_REPORT'
  },
  derivative: {
    label: $t('valuation.assets.derivative'),
    value: 'TRADE_CALCULATE_POSITION'
  },
  counterparty: {
    label: $t('common.counterparty'),
    value: 'COUNTERPARTY'
  },
  transfer: {
    label: $t('report.transfer'),
    value: 'TRANSACTION'
  },
  trade: {
    label: $t('common.trade'),
    value: 'TRADE'
  },
  gainLoss: {
    label: $t('project.costBasis.gainOrLoss'),
    value: 'GAIN_LOSS'
  },
  crypto: {
    label: $t('title.cryptoSummary'),
    value: 'VALUATION_CRYPTO'
  },
  fiat: {
    label: $t('title.fiatSummary'),
    value: 'VALUATION_FIAT'
  },
  broker: {
    label: $t('title.brokerInventorySummary'),
    value: 'VALUATION_BROKER'
  },
  journals: {
    label: $t('report.Journal'),
    value: 'JOURNAL_ENTRY'
  },
  trialBalance: {
    label: $t('project.nav.trialBalance'),
    value: 'TRIAL_BALANCE'
  },
  accountBalance: {
    label: $t('report.accountBalance'),
    value: 'ACCOUNT_BALANCE'
  },
  reconciliationMatches: {
    label: $t('report.matches'),
    value: 'RECONCILIATION_MATCHES'
  },
  reconciliationReport: {
    label: $t('reconciliation.reconciliationReports'),
    value: 'RECONCILIATION_REPORT'
  },
  balanceSheet: {
    label: $t('project.nav.balanceSheet'),
    value: 'REPORT_BALANCE_SHEET',
    reportValue: 'BALANCE_SHEET'
  },
  incomeStatement: {
    label: $t('project.nav.incomeStatement'),
    value: 'REPORT_INCOME_STATEMENT',
    reportValue: 'INCOME_STATEMENT'
  },
  cashFlowStatement: {
    label: $t('project.nav.cashFlowStatement'),
    value: 'REPORT_CASH_FLOW_STATEMENT',
    reportValue: 'CASH_FLOW_STATEMENT'
  },
  significantHoldings: {
    label: $t('project.nav.SignificantHoldings'),
    value: 'REPORT_SIGNIFICANT_HOLDINGS',
    reportValue: 'SIGNIFICANT_HOLDINGS'
  },
  restrictions: {
    label: $t('project.nav.Restrictions'),
    value: 'REPORT_RESTRICTIONS',
    reportValue: 'RESTRICTIONS_OF_CRYPTO_ASSETS'
  },
  rollForward: {
    label: $t('project.nav.rollForward'),
    value: 'REPORT_ROLL_FORWARD',
    reportValue: 'CRYPTO_ASSETS_ROLL_FORWARD'
  }
}
